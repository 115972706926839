import * as vt from 'vue-toastification';
import 'vue-toastification/dist/index.css';

export default defineNuxtPlugin(nuxtApp => {
    const options: vt.PluginOptions = {
        position: vt.POSITION.BOTTOM_CENTER,
        maxToasts: 1,
    };

    nuxtApp.vueApp.use(vt.default, options);

    return {
        provide: {
            toast: vt.useToast(),
        },
    };
});
