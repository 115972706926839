import { default as indexP8StgJOSKzMeta } from "/src/pages/account/index.vue?macro=true";
import { default as _91id_93EqLxRIQgALMeta } from "/src/pages/account/qualifications/[id].vue?macro=true";
import { default as _91id_93RHDnqdgMauMeta } from "/src/pages/admin/accreditations/[id].vue?macro=true";
import { default as indexeKTmKq5TkIMeta } from "/src/pages/admin/accreditations/index.vue?macro=true";
import { default as _91id_93Vupc0ak1ylMeta } from "/src/pages/admin/cases/advice/[id].vue?macro=true";
import { default as indexFIeTW0W9PZMeta } from "/src/pages/admin/cases/advice/index.vue?macro=true";
import { default as _91id_93ebn61Jwu7wMeta } from "/src/pages/admin/cases/categories/[id].vue?macro=true";
import { default as indexPA14omFt0hMeta } from "/src/pages/admin/cases/categories/index.vue?macro=true";
import { default as _91id_93Kk4KanY70nMeta } from "/src/pages/admin/cases/categories/sets/[id].vue?macro=true";
import { default as indexly5nL4lZbLMeta } from "/src/pages/admin/cases/categories/sets/index.vue?macro=true";
import { default as _91id_93sL10KSKqMWMeta } from "/src/pages/admin/cases/consumables/[id].vue?macro=true";
import { default as index2r4rxhCmaZMeta } from "/src/pages/admin/cases/consumables/index.vue?macro=true";
import { default as _91id_93goIYoXzMgeMeta } from "/src/pages/admin/cases/drugs/[id].vue?macro=true";
import { default as index5V5WS8A3h5Meta } from "/src/pages/admin/cases/drugs/index.vue?macro=true";
import { default as _91id_93aeQjocuc4VMeta } from "/src/pages/admin/clients/[id].vue?macro=true";
import { default as indexYQKFhSnc4SMeta } from "/src/pages/admin/clients/index.vue?macro=true";
import { default as _91id_93qaQkAJvI1CMeta } from "/src/pages/admin/events/[id].vue?macro=true";
import { default as eoisZMqMVTsSsoMeta } from "/src/pages/admin/events/eois.vue?macro=true";
import { default as indexIPTL4fc0xrMeta } from "/src/pages/admin/events/index.vue?macro=true";
import { default as _91id_93sKjGWlc1G7Meta } from "/src/pages/admin/events/report/[id].vue?macro=true";
import { default as schemaoepcjfsOWdMeta } from "/src/pages/admin/events/schema.ts?macro=true";
import { default as _91id_932zVN2iwTE9Meta } from "/src/pages/admin/events/types/[id].vue?macro=true";
import { default as indexJjWUu4fhS4Meta } from "/src/pages/admin/events/types/index.vue?macro=true";
import { default as _91id_93AeV06r0sgpMeta } from "/src/pages/admin/fill-shifts/[id].vue?macro=true";
import { default as index46yZusLqosMeta } from "/src/pages/admin/fill-shifts/index.vue?macro=true";
import { default as _91id_93Jow3KOJxfXMeta } from "/src/pages/admin/hospitals/[id].vue?macro=true";
import { default as indexdp5nymc2vIMeta } from "/src/pages/admin/hospitals/index.vue?macro=true";
import { default as indexwK3fSZ6B4uMeta } from "/src/pages/admin/index.vue?macro=true";
import { default as organisationehgmbm2etpMeta } from "/src/pages/admin/organisation.vue?macro=true";
import { default as _91id_93ijPWKv5RMhMeta } from "/src/pages/admin/qualifications/[id].vue?macro=true";
import { default as _91id_93CWiPb62y63Meta } from "/src/pages/admin/qualifications/documents/[id].vue?macro=true";
import { default as index0XqPNwTDhiMeta } from "/src/pages/admin/qualifications/documents/index.vue?macro=true";
import { default as indexGFG12eSbqxMeta } from "/src/pages/admin/qualifications/index.vue?macro=true";
import { default as _91id_93bth8tHjcOcMeta } from "/src/pages/admin/regions/[id].vue?macro=true";
import { default as indexTRsUWQE4kfMeta } from "/src/pages/admin/regions/index.vue?macro=true";
import { default as _91id_93cJf79TBEopMeta } from "/src/pages/admin/resources/[id].vue?macro=true";
import { default as indexerkL50ZzUZMeta } from "/src/pages/admin/resources/index.vue?macro=true";
import { default as _91id_93hwQdFmrAENMeta } from "/src/pages/admin/skillsets/[id].vue?macro=true";
import { default as indexXpkNJhzJVAMeta } from "/src/pages/admin/skillsets/index.vue?macro=true";
import { default as slackGnlXjrrYhKMeta } from "/src/pages/admin/slack.vue?macro=true";
import { default as sms_45emailfqXRzWaekYMeta } from "/src/pages/admin/sms-email.vue?macro=true";
import { default as _91id_93EnUtB6saX0Meta } from "/src/pages/admin/templates/[id].vue?macro=true";
import { default as indexMimQN6lvIZMeta } from "/src/pages/admin/templates/index.vue?macro=true";
import { default as _91id_931vb56mPQEFMeta } from "/src/pages/admin/timesheets/[id].vue?macro=true";
import { default as indexEA75LcB0UQMeta } from "/src/pages/admin/timesheets/index.vue?macro=true";
import { default as _91id_93fhmgqrJ2l1Meta } from "/src/pages/admin/users/[id].vue?macro=true";
import { default as indexcfHL1cVNnBMeta } from "/src/pages/admin/users/index.vue?macro=true";
import { default as adviceugFmVc0hMrMeta } from "/src/pages/cases/[id]/advice.vue?macro=true";
import { default as attachmentsETMM44UviJMeta } from "/src/pages/cases/[id]/attachments.vue?macro=true";
import { default as axzxBVjDDOq5Meta } from "/src/pages/cases/[id]/ax.vue?macro=true";
import { default as chief_45complaintHwZcYsq458Meta } from "/src/pages/cases/[id]/chief-complaint.vue?macro=true";
import { default as consumablesewBbt5yFukMeta } from "/src/pages/cases/[id]/consumables.vue?macro=true";
import { default as debugxTLVbWGX0FMeta } from "/src/pages/cases/[id]/debug.vue?macro=true";
import { default as finaliseMklNyDROUoMeta } from "/src/pages/cases/[id]/finalise.vue?macro=true";
import { default as indexyVYi7AF4UuMeta } from "/src/pages/cases/[id]/index.vue?macro=true";
import { default as pmhxzRxZsfbEebMeta } from "/src/pages/cases/[id]/pmhx.vue?macro=true";
import { default as rxNcih4XVhAAMeta } from "/src/pages/cases/[id]/rx.vue?macro=true";
import { default as sketchesLXJlCpnfWNMeta } from "/src/pages/cases/[id]/sketches.vue?macro=true";
import { default as _91id_93UlRT6JYSyHMeta } from "/src/pages/cases/[id].vue?macro=true";
import { default as _91id_93QACm59NrymMeta } from "/src/pages/cases/audit/[id].vue?macro=true";
import { default as indexdLMVElXvIXMeta } from "/src/pages/cases/audit/index.vue?macro=true";
import { default as indexfBYf44eeYfMeta } from "/src/pages/cases/index.vue?macro=true";
import { default as minesRmr64FiZfMeta } from "/src/pages/cases/mine.vue?macro=true";
import { default as schemafOwjw2F2xFMeta } from "/src/pages/cases/schema.ts?macro=true";
import { default as indexzhYIMAz3QcMeta } from "/src/pages/files/index.vue?macro=true";
import { default as hcEKyTJPuhUjMeta } from "/src/pages/hc.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as indexz0ZORWmtqyMeta } from "/src/pages/login/index.vue?macro=true";
import { default as indexbxKFjMa4X7Meta } from "/src/pages/login/reset-password/index.vue?macro=true";
import { default as setQZ2X2e6xFnMeta } from "/src/pages/login/reset-password/set.vue?macro=true";
import { default as set_45passworddj6WrxuQn2Meta } from "/src/pages/login/set-password.vue?macro=true";
import { default as two_45factor_45authOxMThbtCfGMeta } from "/src/pages/login/two-factor-auth.vue?macro=true";
import { default as eoisHSBJ4K0Sh9Meta } from "/src/pages/shifts/eois.vue?macro=true";
import { default as indexfXHT4DIt1sMeta } from "/src/pages/shifts/index.vue?macro=true";
import { default as upcomingAx51UnyJjpMeta } from "/src/pages/shifts/upcoming.vue?macro=true";
import { default as shiftswH5iLVzUtuMeta } from "/src/pages/shifts.vue?macro=true";
import { default as indexFU3h3UxEg5Meta } from "~/pages/files/index.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-qualifications-id",
    path: "/account/qualifications/:id()",
    component: () => import("/src/pages/account/qualifications/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-accreditations-id",
    path: "/admin/accreditations/:id()",
    meta: _91id_93RHDnqdgMauMeta || {},
    component: () => import("/src/pages/admin/accreditations/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-accreditations",
    path: "/admin/accreditations",
    meta: indexeKTmKq5TkIMeta || {},
    component: () => import("/src/pages/admin/accreditations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-advice-id",
    path: "/admin/cases/advice/:id()",
    meta: _91id_93Vupc0ak1ylMeta || {},
    component: () => import("/src/pages/admin/cases/advice/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-advice",
    path: "/admin/cases/advice",
    meta: indexFIeTW0W9PZMeta || {},
    component: () => import("/src/pages/admin/cases/advice/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-categories-id",
    path: "/admin/cases/categories/:id()",
    meta: _91id_93ebn61Jwu7wMeta || {},
    component: () => import("/src/pages/admin/cases/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-categories",
    path: "/admin/cases/categories",
    meta: indexPA14omFt0hMeta || {},
    component: () => import("/src/pages/admin/cases/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-categories-sets-id",
    path: "/admin/cases/categories/sets/:id()",
    meta: _91id_93Kk4KanY70nMeta || {},
    component: () => import("/src/pages/admin/cases/categories/sets/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-categories-sets",
    path: "/admin/cases/categories/sets",
    meta: indexly5nL4lZbLMeta || {},
    component: () => import("/src/pages/admin/cases/categories/sets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-consumables-id",
    path: "/admin/cases/consumables/:id()",
    meta: _91id_93sL10KSKqMWMeta || {},
    component: () => import("/src/pages/admin/cases/consumables/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-consumables",
    path: "/admin/cases/consumables",
    meta: index2r4rxhCmaZMeta || {},
    component: () => import("/src/pages/admin/cases/consumables/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-drugs-id",
    path: "/admin/cases/drugs/:id()",
    meta: _91id_93goIYoXzMgeMeta || {},
    component: () => import("/src/pages/admin/cases/drugs/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-cases-drugs",
    path: "/admin/cases/drugs",
    meta: index5V5WS8A3h5Meta || {},
    component: () => import("/src/pages/admin/cases/drugs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clients-id",
    path: "/admin/clients/:id()",
    meta: _91id_93aeQjocuc4VMeta || {},
    component: () => import("/src/pages/admin/clients/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-clients",
    path: "/admin/clients",
    meta: indexYQKFhSnc4SMeta || {},
    component: () => import("/src/pages/admin/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-events-id",
    path: "/admin/events/:id()",
    meta: _91id_93qaQkAJvI1CMeta || {},
    component: () => import("/src/pages/admin/events/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-events-eois",
    path: "/admin/events/eois",
    meta: eoisZMqMVTsSsoMeta || {},
    component: () => import("/src/pages/admin/events/eois.vue").then(m => m.default || m)
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indexIPTL4fc0xrMeta || {},
    component: () => import("/src/pages/admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-events-report-id",
    path: "/admin/events/report/:id()",
    meta: _91id_93sKjGWlc1G7Meta || {},
    component: () => import("/src/pages/admin/events/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-events-schema",
    path: "/admin/events/schema",
    component: () => import("/src/pages/admin/events/schema.ts").then(m => m.default || m)
  },
  {
    name: "admin-events-types-id",
    path: "/admin/events/types/:id()",
    meta: _91id_932zVN2iwTE9Meta || {},
    component: () => import("/src/pages/admin/events/types/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-events-types",
    path: "/admin/events/types",
    meta: indexJjWUu4fhS4Meta || {},
    component: () => import("/src/pages/admin/events/types/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-fill-shifts-id",
    path: "/admin/fill-shifts/:id()",
    meta: _91id_93AeV06r0sgpMeta || {},
    component: () => import("/src/pages/admin/fill-shifts/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-fill-shifts",
    path: "/admin/fill-shifts",
    meta: index46yZusLqosMeta || {},
    component: () => import("/src/pages/admin/fill-shifts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-hospitals-id",
    path: "/admin/hospitals/:id()",
    meta: _91id_93Jow3KOJxfXMeta || {},
    component: () => import("/src/pages/admin/hospitals/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-hospitals",
    path: "/admin/hospitals",
    meta: indexdp5nymc2vIMeta || {},
    component: () => import("/src/pages/admin/hospitals/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisation",
    path: "/admin/organisation",
    meta: organisationehgmbm2etpMeta || {},
    component: () => import("/src/pages/admin/organisation.vue").then(m => m.default || m)
  },
  {
    name: "admin-qualifications-id",
    path: "/admin/qualifications/:id()",
    meta: _91id_93ijPWKv5RMhMeta || {},
    component: () => import("/src/pages/admin/qualifications/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-qualifications-documents-id",
    path: "/admin/qualifications/documents/:id()",
    component: () => import("/src/pages/admin/qualifications/documents/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-qualifications-documents",
    path: "/admin/qualifications/documents",
    meta: index0XqPNwTDhiMeta || {},
    component: () => import("/src/pages/admin/qualifications/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-qualifications",
    path: "/admin/qualifications",
    meta: indexGFG12eSbqxMeta || {},
    component: () => import("/src/pages/admin/qualifications/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-regions-id",
    path: "/admin/regions/:id()",
    meta: _91id_93bth8tHjcOcMeta || {},
    component: () => import("/src/pages/admin/regions/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-regions",
    path: "/admin/regions",
    meta: indexTRsUWQE4kfMeta || {},
    component: () => import("/src/pages/admin/regions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-resources-id",
    path: "/admin/resources/:id()",
    meta: _91id_93cJf79TBEopMeta || {},
    component: () => import("/src/pages/admin/resources/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-resources",
    path: "/admin/resources",
    meta: indexerkL50ZzUZMeta || {},
    component: () => import("/src/pages/admin/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-skillsets-id",
    path: "/admin/skillsets/:id()",
    meta: _91id_93hwQdFmrAENMeta || {},
    component: () => import("/src/pages/admin/skillsets/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-skillsets",
    path: "/admin/skillsets",
    meta: indexXpkNJhzJVAMeta || {},
    component: () => import("/src/pages/admin/skillsets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-slack",
    path: "/admin/slack",
    component: () => import("/src/pages/admin/slack.vue").then(m => m.default || m)
  },
  {
    name: "admin-sms-email",
    path: "/admin/sms-email",
    meta: sms_45emailfqXRzWaekYMeta || {},
    component: () => import("/src/pages/admin/sms-email.vue").then(m => m.default || m)
  },
  {
    name: "admin-templates-id",
    path: "/admin/templates/:id()",
    meta: _91id_93EnUtB6saX0Meta || {},
    component: () => import("/src/pages/admin/templates/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-templates",
    path: "/admin/templates",
    meta: indexMimQN6lvIZMeta || {},
    component: () => import("/src/pages/admin/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-id",
    path: "/admin/timesheets/:id()",
    meta: _91id_931vb56mPQEFMeta || {},
    component: () => import("/src/pages/admin/timesheets/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    meta: indexEA75LcB0UQMeta || {},
    component: () => import("/src/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    meta: _91id_93fhmgqrJ2l1Meta || {},
    component: () => import("/src/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexcfHL1cVNnBMeta || {},
    component: () => import("/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UlRT6JYSyHMeta?.name,
    path: "/cases/:id()",
    component: () => import("/src/pages/cases/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "cases-id-advice",
    path: "advice",
    component: () => import("/src/pages/cases/[id]/advice.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-attachments",
    path: "attachments",
    component: () => import("/src/pages/cases/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-ax",
    path: "ax",
    component: () => import("/src/pages/cases/[id]/ax.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-chief-complaint",
    path: "chief-complaint",
    component: () => import("/src/pages/cases/[id]/chief-complaint.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-consumables",
    path: "consumables",
    component: () => import("/src/pages/cases/[id]/consumables.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-debug",
    path: "debug",
    component: () => import("/src/pages/cases/[id]/debug.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-finalise",
    path: "finalise",
    component: () => import("/src/pages/cases/[id]/finalise.vue").then(m => m.default || m)
  },
  {
    name: "cases-id",
    path: "",
    component: () => import("/src/pages/cases/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-pmhx",
    path: "pmhx",
    component: () => import("/src/pages/cases/[id]/pmhx.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-rx",
    path: "rx",
    component: () => import("/src/pages/cases/[id]/rx.vue").then(m => m.default || m)
  },
  {
    name: "cases-id-sketches",
    path: "sketches",
    component: () => import("/src/pages/cases/[id]/sketches.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cases-audit-id",
    path: "/cases/audit/:id()",
    component: () => import("/src/pages/cases/audit/[id].vue").then(m => m.default || m)
  },
  {
    name: "cases-audit",
    path: "/cases/audit",
    meta: indexdLMVElXvIXMeta || {},
    component: () => import("/src/pages/cases/audit/index.vue").then(m => m.default || m)
  },
  {
    name: "cases",
    path: "/cases",
    component: () => import("/src/pages/cases/index.vue").then(m => m.default || m)
  },
  {
    name: "cases-mine",
    path: "/cases/mine",
    component: () => import("/src/pages/cases/mine.vue").then(m => m.default || m)
  },
  {
    name: "cases-schema",
    path: "/cases/schema",
    component: () => import("/src/pages/cases/schema.ts").then(m => m.default || m)
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/src/pages/files/index.vue").then(m => m.default || m)
  },
  {
    name: "hc",
    path: "/hc",
    meta: hcEKyTJPuhUjMeta || {},
    component: () => import("/src/pages/hc.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexz0ZORWmtqyMeta || {},
    component: () => import("/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-reset-password",
    path: "/login/reset-password",
    meta: indexbxKFjMa4X7Meta || {},
    component: () => import("/src/pages/login/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "login-reset-password-set",
    path: "/login/reset-password/set",
    meta: setQZ2X2e6xFnMeta || {},
    component: () => import("/src/pages/login/reset-password/set.vue").then(m => m.default || m)
  },
  {
    name: "login-set-password",
    path: "/login/set-password",
    meta: set_45passworddj6WrxuQn2Meta || {},
    component: () => import("/src/pages/login/set-password.vue").then(m => m.default || m)
  },
  {
    name: "login-two-factor-auth",
    path: "/login/two-factor-auth",
    meta: two_45factor_45authOxMThbtCfGMeta || {},
    component: () => import("/src/pages/login/two-factor-auth.vue").then(m => m.default || m)
  },
  {
    name: shiftswH5iLVzUtuMeta?.name,
    path: "/shifts",
    component: () => import("/src/pages/shifts.vue").then(m => m.default || m),
    children: [
  {
    name: "shifts-eois",
    path: "eois",
    component: () => import("/src/pages/shifts/eois.vue").then(m => m.default || m)
  },
  {
    name: "shifts",
    path: "",
    component: () => import("/src/pages/shifts/index.vue").then(m => m.default || m)
  },
  {
    name: "shifts-upcoming",
    path: "upcoming",
    component: () => import("/src/pages/shifts/upcoming.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "files-folder",
    path: "/files/:id",
    component: () => import("~/pages/files/index.vue").then(m => m.default || m)
  }
]