import type { TenantType } from '~/generated/types';
import { tenantFragment } from '~/graphql/fragments/tenants';

export type TenantData = {
    tenant: TenantType
}

export const GET_PUBLIC_TENANT_QUERY = gql`
    query GetPublicTenant {
        tenant {
            id
            name
            logoDefault {
                url
            }
            logoDark {
                url
            }
            empCoverImage {
                url
            }
        }
    }
`;


export const GET_TENANT_QUERY = gql`
    query GetTenant {
        tenant {
            ...tenantFragment
        }
    }
    
    ${tenantFragment}
`;

export const GET_TENANT_POST_EVENT_EMAIL_QUERY = gql`
    query GetTenant {
        tenant {
            id
            emailEventPostReport
        }
    }
`;
