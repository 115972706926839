<template>
    <main class="w-screen">
        <div class="container">
            <div class="max-w-sm mx-auto text-center">
                <div class="p-12 bg-white rounded-lg">
                    <h1 class="h1 mb-4">Apollo EMS</h1>

                    <div>
                        <h2 class="h2">{{ props.error!.statusCode }}</h2>
                        <p>{{ props.error!.message }}</p>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
    import type { NuxtError } from '#app';

    const props = defineProps({
        // eslint-disable-next-line vue/require-default-prop
        error: Object as () => NuxtError,
    });

    useHead({
        htmlAttrs: {
            lang: 'en',
            class: 'error-page',
        },
    });
</script>

<style lang="postcss">
    html.error-page,
    html.error-page body {
        @apply m-0 p-0 min-h-screen;
    }

    html.error-page body {
        @apply flex items-center w-full;
        @apply bg-gradient-to-br from-[#182C3F] to-[#375C7B];
    }
</style>
