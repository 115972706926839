import { addressFragment } from '~/graphql/fragments/address';

export const tenantFragment = gql`
    fragment tenantFragment on TenantType {
        id
        name
        email
        phone
        website
        abn
        address {
            ...addressFragment
        }
        logoDefault {
            name
            url
        }
        logoDark {
            name
            url
        }
        empCoverImage {
            name
            url
        }
        empEquipment
        empInsurance
        empTerms
        postReportTemplate
        emailEmp
        emailSemp
        emailEoiAssigned
        emailEoiUnsuccessful
        emailEventConfirmation
        emailEventPostReport
        slackIsConnected
        slackChannel
    }

    ${addressFragment}
`;
