import { addressFragment } from '~/graphql/fragments/address';

export const userFragment = gql`
    fragment userFragment on UserType {
        id
        uuid
        firstName
        lastName
        fullName
        email
        phone
        address {
            ...addressFragment
        }
        region {
            id
            name
        }
        skillset {
            id
            name
            order
        }
        accreditations {
            id
            name
        }
        qualificationDocuments {
            id
        }
        dateJoined
        lastLogin
        isActive
        isSuperuser
        permissions
        canRegisterInterest
        twoFactorEnabled
        bypassQualificationRequirements
        hasUsablePassword
    }
    
    ${addressFragment}
`;
