export interface SystemState {
    darkMode: boolean | undefined;
}

export const useSystemStore = defineStore('system', {
    state: (): SystemState => {
        return {
            darkMode: undefined,
        };
    },
    persist: {
        storage: persistedState.cookiesWithOptions({
            sameSite: 'strict',
        }),
    },
});
