import type { ModuleOptions } from '~/modules/auth/runtime/types';
import type { UserType } from '~/generated/types';
import isArray from 'lodash/isArray';

export default defineNuxtRouteMiddleware(async to => {
    const { performTokenRefresh, getSession } = useAuth();
    const { pathname, search, searchParams } = useRequestURL();
    const { isTokenValid, is2FaAuthenticated, data } = useAuthState();

    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const user = computed<UserType | null>(() => data.value || null);

    if (to.meta.disableAuth) {
        return;
    }

    await performTokenRefresh();

    if (!data.value && is2FaAuthenticated.value) {
        await getSession();
    }

    if (isTokenValid.value) {
        if (!is2FaAuthenticated.value) {
            if (to.meta.twoFactorAuth) {
                return;
            }

            return navigateTo(config.pages.twoFactorAuth);
        }

        if (to.meta.anonymous) {
            return navigateTo(config.pages.home);
        }

        if (to.meta.permission) {
            let hasPermission = false;

            if (to.meta.permission === true) {
                hasPermission = user.value?.isSuperuser === true;
            } else {
                if (isArray(to.meta.permission)) {
                    const permissions = to.meta.permission as string[];
                    hasPermission = user.value?.permissions.some(permission => permissions.includes(permission)) ?? false;
                } else {
                    hasPermission = user.value?.permissions.includes(to.meta.permission as string) ?? false;
                }
            }

            if (!hasPermission) {
                console.log('User does not have permission to access this page.', data.value);

                throw createError({
                    fatal: true,
                    statusCode: 401,
                    statusMessage: 'Unauthorized',
                });
            }
        }

        return;
    }

    if (to.meta.anonymous) {
        return;
    }

    const next = encodeURIComponent(searchParams.get('next') || `${pathname}${search}`);
    return navigateTo(`${config.pages.login}?next=${next}`);
});
