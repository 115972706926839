export default defineNuxtPlugin({
    name: 'apollo-auth',
    async setup(nuxtApp) {
        const { token: authToken } = useAuthState();
        const { performTokenRefresh } = useAuth();

        nuxtApp.hook('apollo:auth', async({ client, token }) => {
            if (client === 'default') {
                await performTokenRefresh();

                if (authToken.value) {
                    token.value = authToken.value;
                }
            }
        });
    },
});
