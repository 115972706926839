import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink, from, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { NuxtApollo } from '#apollo';
import createRestartableClient from '~/utils/ws';
import { onError } from '@apollo/client/link/error';

export default defineNuxtPlugin({
    name: 'apollo-link',
    dependsOn: [ 'apollo-auth' ],
    setup(nuxtApp) {
        const { $apollo } = useNuxtApp();
        const { protocol, host } = useRequestURL();
        const { start, finish } = useLoadingIndicator({
            duration: 1000,
            throttle: 200,
        });

        const requestCookies = (import.meta.server && NuxtApollo.proxyCookies && useRequestHeaders([ 'cookie' ])) || undefined;

        const getAuth = async() => {
            const token = ref<string | null>(null);

            // @ts-expect-error
            await nuxtApp.callHook('apollo:auth', { token, client: 'default' });

            return token.value;
        };

        const loadingLink = new ApolloLink((operation, forward) => {
            if (import.meta.client) {
                start();
            }

            return forward(operation).map(data => {
                if (import.meta.client) {
                    finish();
                }

                return data;
            });
        });

        const authLink = setContext(async(a, { headers }) => {
            const auth = await getAuth();

            if (!auth) { return; }

            return {
                headers: {
                    ...headers,
                    ...(requestCookies && requestCookies),
                    Authorization: auth,
                },
            };
        });

        let wsLink: GraphQLWsLink | null = null;

        if (import.meta.client) {
            const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
            const wsUrl = process.dev ? `${wsProtocol}//127.0.0.1:8000/graphql/` : `${wsProtocol}//${host}/graphql/`;

            const wsClient = createRestartableClient({
                url: wsUrl,
                retryAttempts: 10,
                shouldRetry(errOrCloseEvent) {
                    return true;
                },
                connectionParams: async() => {
                    const auth = await getAuth();

                    if (!auth) { return; }

                    return {
                        Authorization: auth,
                        headers: {
                            Authorization: auth,
                        },
                    };
                },
            });

            wsLink = new GraphQLWsLink(wsClient);

            nuxtApp._apolloWsClients = nuxtApp._apolloWsClients || {};

            // @ts-ignore
            nuxtApp._apolloWsClients['default'] = wsClient;
        }

        const httpLink = createUploadLink({
            uri: `${protocol}//${host}/graphql/`,
        });

        const splitLink = wsLink ? split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
            },
            wsLink,
            httpLink
        ) : httpLink;

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) => {
                    console.log(
                        `[GraphQL error]: Message: ${message}`,
                        locations,
                        path
                    );

                    if (message.includes('matching query does not exist')) {
                        throw createError({
                            fatal: true,
                            statusCode: 404,
                            statusMessage: message,
                        });
                    }
                });
            }

            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }
        });

        // @ts-expect-error
        $apollo.clients.default.setLink(from([ loadingLink, errorLink, authLink, splitLink ]));

        // @ts-expect-error
        $apollo.clients.noAuth.setLink(from([ loadingLink, errorLink, httpLink ]));
    },
});
