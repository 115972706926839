import mitt from 'mitt';
import type { TenantType } from '~/generated/types';

type ApplicationEvents = {
    'menu:opened': void;
    'modal:closed': void;
    'modal:result': void;
    'app:reloadTenant': TenantType;
};

export default defineNuxtPlugin(() => {
    const emitter = mitt<ApplicationEvents>();

    return {
        provide: {
            event: emitter.emit,
            listen: emitter.on,
        },
    };
});
