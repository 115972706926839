export const addressFragment = gql`
    fragment addressFragment on AddressType {
        id
        raw
        formatted
        streetNumber
        route
        latitude
        longitude
        locality {
            id
            name
            postalCode
            state {
                id
                name
                code
                country {
                    id
                    name
                    code
                }
            }
        }
    }
`;
