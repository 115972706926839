import { obtainJSONWebToken } from '~/modules/auth/graphql/fragments/auth';
import type { ObtainJsonWebTokenType } from '~/generated/types';

export type TwoFactorAuthInput = {
    token: string
    oneTimePassword: string
};

export type TwoFactorAuthData = {
    twoFactorAuth: ObtainJsonWebTokenType
}

export const TWO_FACTOR_AUTH_MUTATION = gql`
    mutation twoFactor($token: String!, $oneTimePassword: String!) {
        twoFactorAuth(token: $token, oneTimePassword: $oneTimePassword) {
            ...obtainJSONWebToken
        }
    }
    
    ${obtainJSONWebToken}
`;
