import { readonly } from 'vue';
import { useAuthState } from './useAuthState';
import type { NavigationFailure, RouteLocationRaw } from 'vue-router';
import { FetchError } from 'ofetch';
import type { ObtainJsonWebTokenType } from '~/generated/types';
import type { GetSessionOptions, ModuleOptions } from '~/modules/auth/runtime/types';
import {
    LOGIN_MUTATION,
    type LoginData,
    type LoginInput,
} from '~/modules/auth/graphql/mutations/login';
import {
    SESSION_QUERY,
    type SessionData,
} from '~/modules/auth/graphql/queries/session';
import {
    REFRESH_MUTATION,
    type RefreshData,
    type RefreshInput,
} from '~/modules/auth/graphql/mutations/refresh';
import {
    LOGOUT_MUTATION,
    type LogoutData,
    type LogoutInput,
} from '~/modules/auth/graphql/mutations/logout';
import {
    TWO_FACTOR_AUTH_MUTATION,
    type TwoFactorAuthData,
    type TwoFactorAuthInput,
} from '~/modules/auth/graphql/mutations/two-factor';

type Credentials = { email: string, password: string } & Record<string, any>

export class SignInError extends Error {
    public errors: object;

    constructor(errors: object) {
        super('Invalid credentials');
        this.errors = errors;
    }
}

const signIn = async(credentials: Credentials): Promise<null | ObtainJsonWebTokenType | false | void | RouteLocationRaw | NavigationFailure> => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const { searchParams } = useRequestURL();

    const { mutate } = useMutation<LoginData, LoginInput>(LOGIN_MUTATION, {
        clientId: 'noAuth',
        variables: credentials,
    });

    const result = await mutate();

    const token = result?.data?.tokenAuth.token?.token || null;
    const refreshToken = result?.data?.tokenAuth.refreshToken?.token || null;
    const tokenExpiresAt = result?.data?.tokenAuth.token?.payload.exp || null;
    const refreshTokenExpiresAt = result?.data?.tokenAuth.refreshToken?.expiresAt || null;
    const is2faAuthenticated = result?.data?.tokenAuth.token?.payload.is2faAuthenticated || false;

    const {
        rawToken,
        rawIs2FaAuthenticated,
        rawRefreshToken,
        rawTokenExpiresAt,
        rawRefreshTokenExpiresAt,
    } = useAuthState();

    rawToken.value = token;
    rawRefreshToken.value = refreshToken;
    rawTokenExpiresAt.value = tokenExpiresAt;
    rawRefreshTokenExpiresAt.value = refreshTokenExpiresAt;
    rawIs2FaAuthenticated.value = is2faAuthenticated;

    if (result?.data?.tokenAuth.errors) {
        throw new SignInError(result.data.tokenAuth.errors);
    }

    const next = encodeURIComponent(searchParams.get('next') || config.pages.home);

    if (!is2faAuthenticated) {
        return navigateTo(`${config.pages.twoFactorAuth}?next=${next}`);
    }

    await nextTick(getSession);
    return navigateTo(next || config.pages.home);
};

const signOut = async() => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const { clients, onLogout } = useApollo();

    const {
        refreshToken,
        data,
        loading,
        rawToken,
        rawRefreshToken,
        rawTokenExpiresAt,
        rawRefreshTokenExpiresAt,
    } = useAuthState();

    if (refreshToken.value) {
        loading.value = true;

        const { mutate } = useMutation<LogoutData, LogoutInput>(LOGOUT_MUTATION, {
            variables: {
                refreshToken: refreshToken.value!,
            },
        });

        await mutate();

        loading.value = false;
    }

    data.value = null;
    rawToken.value = null;
    rawRefreshToken.value = null;
    rawTokenExpiresAt.value = null;
    rawRefreshTokenExpiresAt.value = null;

    clients?.default.cache.reset();
    await onLogout('default', false);
    await navigateTo(config.pages.login);
};

const logInTwoFactor = async(oneTimePassword: string): Promise<boolean> => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const {
        rawToken,
        rawRefreshToken,
        rawTokenExpiresAt,
        rawRefreshTokenExpiresAt,
        rawIs2FaAuthenticated,
        lastRefreshedAt,
    } = useAuthState();
    const { searchParams } = useRequestURL();

    const { mutate } = useMutation<TwoFactorAuthData, TwoFactorAuthInput>(TWO_FACTOR_AUTH_MUTATION, {
        clientId: 'noAuth',
        variables: {
            token: rawToken.value!,
            oneTimePassword,
        },
    });

    const result = await mutate();
    const errors = result?.errors || [];

    if (errors.length > 0) {
        throw createError({
            fatal: true,
            statusCode: 501,
            message: 'Bad Gateway',
        });
    }

    const token = result?.data?.twoFactorAuth.token?.token || null;
    const tokenExpiresAt = result?.data?.twoFactorAuth.token?.payload.exp || null;
    const newRefreshToken = result?.data?.twoFactorAuth.refreshToken?.token || null;
    const refreshTokenExpiresAt = result?.data?.twoFactorAuth.refreshToken?.expiresAt || null;
    const is2faAuthenticated = result?.data?.twoFactorAuth.token?.payload.is2faAuthenticated || false;

    if (result?.data?.twoFactorAuth.errors) {
        if (result.data.twoFactorAuth.errors.nonFieldErrors?.[0].code === 'expired_token') {
            await navigateTo(config.pages.login);
            return false;
        }

        throw new SignInError(result.data.twoFactorAuth.errors);
    }

    if (token === null) {
        return false;
    }

    rawToken.value = token;
    rawRefreshToken.value = newRefreshToken;
    rawTokenExpiresAt.value = tokenExpiresAt;
    rawRefreshTokenExpiresAt.value = refreshTokenExpiresAt;
    rawIs2FaAuthenticated.value = is2faAuthenticated;
    lastRefreshedAt.value = new Date();

    await nextTick();

    await navigateTo(searchParams.get('next') || config.pages.home);

    return true;
};

const performTokenRefresh = async(): Promise<boolean> => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const {
        isTokenValid,
        isRefreshTokenValid,
        rawToken,
        rawRefreshToken,
        rawTokenExpiresAt,
        rawRefreshTokenExpiresAt,
        rawIs2FaAuthenticated,
        lastRefreshedAt,
    } = useAuthState();
    const { pathname, search, searchParams } = useRequestURL();

    if (isTokenValid.value) {
        return true;
    }

    if (!isRefreshTokenValid.value) {
        return false;
    }

    const { mutate } = useMutation<RefreshData, RefreshInput>(REFRESH_MUTATION, {
        clientId: 'noAuth',
        variables: {
            refreshToken: rawRefreshToken.value!,
        },
    });

    const result = await mutate();
    const errors = result?.errors || [];

    if (errors.length > 0) {
        throw createError({
            fatal: true,
            statusCode: 501,
            message: 'Bad Gateway',
        });
    }

    console.log('Token refreshed');

    const token = result?.data?.refreshToken.token?.token || null;
    const tokenExpiresAt = result?.data?.refreshToken.token?.payload.exp || null;
    const newRefreshToken = result?.data?.refreshToken.refreshToken?.token || null;
    const refreshTokenExpiresAt = result?.data?.refreshToken.refreshToken?.expiresAt || null;
    const is2faAuthenticated = result?.data?.refreshToken.token?.payload.is2faAuthenticated || false;

    rawToken.value = token;
    rawRefreshToken.value = newRefreshToken;
    rawTokenExpiresAt.value = tokenExpiresAt;
    rawRefreshTokenExpiresAt.value = refreshTokenExpiresAt;
    rawIs2FaAuthenticated.value = is2faAuthenticated;
    lastRefreshedAt.value = new Date();

    await nextTick();

    if (token === null) {
        console.log('Token is null, redirecting to login');

        const next = encodeURIComponent(searchParams.get('next') || `${pathname}${search}`);
        await navigateTo(`${config.pages.login}?next=${next}`);
        return false;
    }

    return true;
};

const getSession = async(getSessionOptions?: GetSessionOptions) => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const {
        data,
        loading,
        lastRefreshedAt,
        token,
        rawToken,
        rawRefreshToken,
        rawTokenExpiresAt,
        rawRefreshTokenExpiresAt,
    } = useAuthState();
    const { protocol, host, pathname, search, searchParams } = useRequestURL();

    if (!token.value && !getSessionOptions?.force) {
        console.log('No token, or force is not set');
        return;
    }

    loading.value = true;

    let meData: { data?: SessionData };

    try {
        meData = await $fetch<{data?: SessionData}>(`${protocol}//${host}/graphql/`, {
            method: 'POST',
            body: {
                query: SESSION_QUERY.loc.source.body,
            },
            headers: {
                Authorization: token.value!,
            },
        });
    } catch (err: unknown) {
        if (err instanceof FetchError) {
            if (err.data.statusCode >= 500) {
                throw createError({
                    fatal: true,
                    statusCode: err.data.statusCode,
                    message: err.message,
                });
            }
        }

        return null;
    }

    data.value = meData.data?.me ?? null;

    if (data.value === null) {
        rawToken.value = null;
        rawRefreshToken.value = null;
        rawTokenExpiresAt.value = null;
        rawRefreshTokenExpiresAt.value = null;
    }

    loading.value = false;
    lastRefreshedAt.value = new Date();

    const { required = false, callbackUrl, onUnauthenticated, external } = getSessionOptions ?? {};
    if (required && data.value === null) {
        if (onUnauthenticated) {
            return onUnauthenticated();
        } else {
            const next = encodeURIComponent(searchParams.get('next') || `${pathname}${search}`);
            await navigateTo(callbackUrl ?? `${config.pages.login}?next=${next}`, { external });
        }
    }

    return data.value;
};

interface UseAuthReturn {
    signIn: typeof signIn
    signOut: typeof signOut
    logInTwoFactor: typeof logInTwoFactor
    performTokenRefresh: typeof performTokenRefresh
    getSession: typeof getSession
}

export const useAuth = (): UseAuthReturn => {
    const {
        data,
        status,
        lastRefreshedAt,
        token,
        refreshToken,
    } = useAuthState();

    const getters = {
        status,
        data: readonly(data),
        lastRefreshedAt: readonly(lastRefreshedAt),
        token: readonly(token),
        refreshToken: readonly(refreshToken),
    };

    const actions = {
        signIn,
        signOut,
        logInTwoFactor,
        performTokenRefresh,
        getSession,
    };

    return {
        ...getters,
        ...actions,
    };
};
