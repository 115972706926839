import * as Sentry from '@sentry/vue';
import type { Router } from 'vue-router';
import { withScope } from '@sentry/vue';
import type { UserType } from '~/generated/types';

export default defineNuxtPlugin({
    parallel: true,
    setup: nuxtApp => {
        const config = useRuntimeConfig();

        const authState = useAuthState();
        const user = computed<UserType>(() => authState.data.value);

        Sentry.init({
            enabled: config.public.ENV === 'production',
            app: nuxtApp.vueApp,
            dsn: config.public.sentryDsn as string,
            integrations: [
                Sentry.browserProfilingIntegration(),
                Sentry.browserTracingIntegration({ router: nuxtApp.$router as Router }),
                Sentry.replayIntegration(),
                Sentry.httpClientIntegration(),
                Sentry.reportingObserverIntegration(),
                Sentry.feedbackIntegration({
                    colorScheme: 'system',
                }),
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: [ 'localhost', /^https:\/\/.*\.apolloems\.com\.au\/graphql/ ],
            profilesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            hooks: [ 'activate', 'create', 'destroy', 'mount', 'update' ],
            initialScope: {
                user: user.value ? {
                    id: user.value.id,
                    email: user.value.email,
                    name: user.value.fullName,
                } : undefined,
            },
        });

        watch(user, newUser => {
            if (newUser) {
                Sentry.setUser({
                    id: newUser.id,
                    email: newUser.email,
                    name: user.value.fullName,
                });
            } else {
                Sentry.setUser(null);
            }
        });

        nuxtApp.vueApp.config.errorHandler = (err, context) => {
            withScope(scope => {
                scope.setExtra('context', context);
                Sentry.captureException(err);
            });
        };

        nuxtApp.hook('app:error', err => {
            Sentry.captureException(err);
        });
    },
});
