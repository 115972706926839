import type { RevokeRefreshTokenType } from '~/generated/types';

export type LogoutInput = {
    refreshToken: string
};

export type LogoutData = {
    revokeToken: RevokeRefreshTokenType
}

export const LOGOUT_MUTATION = gql`
    mutation logout($refreshToken: String!) {
        revokeToken(refreshToken: $refreshToken) {
            success
        }
    }
`;
