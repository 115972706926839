import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/src/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_wmkEyTbfx4 from "/src/modules/auth/runtime/plugins/apollo.ts";
import plugin_9mDU73cAbK from "/src/modules/auth/runtime/plugins/plugin.ts";
import plugin_AUP22rrBAc from "/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/src/plugins/apollo.ts";
import collapse_TZkqkRK9EV from "/src/plugins/collapse.ts";
import event_bus_S6Iq1iGe5d from "/src/plugins/event-bus.ts";
import font_awesome_4Kz3WSwqBj from "/src/plugins/font-awesome.ts";
import sentry_client_shVUlIjFLk from "/src/plugins/sentry.client.ts";
import toastification_client_7C0evqwO5y from "/src/plugins/toastification.client.ts";
import v_click_outside_NsCOXhKC9o from "/src/plugins/v-click-outside.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  apollo_wmkEyTbfx4,
  plugin_9mDU73cAbK,
  plugin_AUP22rrBAc,
  apollo_1ILFkzpYSr,
  collapse_TZkqkRK9EV,
  event_bus_S6Iq1iGe5d,
  font_awesome_4Kz3WSwqBj,
  sentry_client_shVUlIjFLk,
  toastification_client_7C0evqwO5y,
  v_click_outside_NsCOXhKC9o
]