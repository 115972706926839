import { obtainJSONWebToken } from '~/modules/auth/graphql/fragments/auth';
import type { ObtainJsonWebTokenType } from '~/generated/types';

export type LoginInput = {
    email: string
    password: string
};

export type LoginData = {
    tokenAuth: ObtainJsonWebTokenType
}

export const LOGIN_MUTATION = gql`
    mutation login($email: String!, $password: String!) {
        tokenAuth(email: $email, password: $password) {
            ...obtainJSONWebToken
        }
    }
    
    ${obtainJSONWebToken}
`;
