import { type UserType } from '~/generated/types';
import { userFragment } from '~/graphql/fragments/user';

export type SessionData = {
    me: UserType
}

export const SESSION_QUERY = gql`
    query Session {
        me {
            ...userFragment
            permissions
            isSuperuser
            canRegisterInterest
            twoFactorEnabled
        }
    }
    
    ${userFragment}
`;
