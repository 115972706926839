import { createClient } from 'graphql-ws';
import type { Client, ClientOptions } from 'graphql-ws';

export interface RestartableClient extends Client {
    restart(): void;
}

export default function createRestartableClient(options: ClientOptions): RestartableClient {
    let restartRequested = false;
    let restart = () => {
        restartRequested = true;
    };

    const client = createClient({
        ...options,
        on: {
            ...options.on,
            opened: (socket: any) => {
                options.on?.opened?.(socket);

                restart = () => {
                    if (socket.readyState === WebSocket.OPEN) {
                        // if the socket is still open for the restart, do the restart
                        socket.close(4205, 'Client Restart');
                    } else {
                        // otherwise the socket might've closed, indicate that you want
                        // a restart on the next opened event
                        restartRequested = true;
                    }
                };

                // just in case you were eager to restart
                if (restartRequested) {
                    restartRequested = false;
                    restart();
                }
            },
        },
    });

    return {
        ...client,
        restart: () => restart(),
    };
}
