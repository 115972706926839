export const obtainJSONWebToken = gql`
    fragment obtainJSONWebToken on ObtainJSONWebTokenType {
        success
        errors
        token {
            token
            payload {
                email
                exp
                is2faAuthenticated
            }
        }
        refreshToken {
            token
            expiresAt
        }
    }
`;
