<template>
    <nuxt-layout>
        <client-only>
            <nuxt-loading-indicator />
        </client-only>

        <nuxt-page
            :keepalive="{
                include: keepAliveComponents.join(','),
            }"
        />
    </nuxt-layout>
</template>

<script setup lang="ts">
    import { GET_PUBLIC_TENANT_QUERY, type TenantData } from '~/graphql/queries/tenants';
    import type { TenantType } from '~/generated/types';

    const tenantConfig = useState<TenantType | undefined>('tenantConfig');
    const systemConfig = useSystemStore();
    const { $listen } = useNuxtApp();
    const route = useRoute();

    const keepAliveComponents = ref<string[]>([
        'EventsIndex',
        'FillShiftsIndex',
        'EOIIndex',
        'QualificationDocumentsIndex',
    ]);

    useHead({
        titleTemplate: titleChunk => {
            if (!tenantConfig.value) {
                return titleChunk || '';
            }

            return titleChunk ? `${titleChunk} | ${tenantConfig.value.name}` : tenantConfig.value.name;
        },
        htmlAttrs: {
            lang: 'en',
            class: () => {
                return {
                    'dark': systemConfig.darkMode ?? false,
                };
            },
        },
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    }, {
        processTemplateParams: true,
    });

    const loadTenant = async() => {
        if (route.path == '/hc') {
            return;
        }

        const response = await useAsyncQuery<TenantData>({
            query: GET_PUBLIC_TENANT_QUERY,
            clientId: 'noAuth',
        });

        const error = response.error.value;
        if (error && error.statusCode >= 500) {
            throw createError({
                fatal: true,
                statusCode: error.statusCode,
                message: error.message,
            });
        }

        tenantConfig.value = response.data.value!.tenant;
    };

    await callOnce(async() => {
        await loadTenant();
    });

    $listen('app:reloadTenant', (tenant: TenantType) => {
        tenantConfig.value = tenant;
    });

    onMounted(() => {
        if (typeof systemConfig.darkMode === 'undefined') {
            systemConfig.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
    });
</script>
