import { obtainJSONWebToken } from '~/modules/auth/graphql/fragments/auth';
import type { ObtainJsonWebTokenType } from '~/generated/types';

export type RefreshInput = {
    refreshToken: string
};

export type RefreshData = {
    refreshToken: ObtainJsonWebTokenType
}

export const REFRESH_MUTATION = gql`
    mutation refresh($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            ...obtainJSONWebToken
        }
    }
    
    ${obtainJSONWebToken}
`;
