import { computed, watch, type ComputedRef } from 'vue';
import type { CookieRef } from '#app';
// @ts-expect-error - #auth not defined
import type { SessionData } from '#auth';
import type { CommonUseAuthStateReturn, ModuleOptions, SessionLastRefreshedAt, SessionStatus } from '../types';


interface UseAuthStateReturn extends CommonUseAuthStateReturn<SessionData> {
    token: ComputedRef<string | null>
    rawToken: CookieRef<string | null>
    is2FaAuthenticated: ComputedRef<boolean | null>
    rawIs2FaAuthenticated: CookieRef<boolean | null>
    tokenExpiresAt: ComputedRef<Date | null>
    rawTokenExpiresAt: CookieRef<string | null>
    refreshToken: ComputedRef<string | null>
    rawRefreshToken: CookieRef<string | null>
    refreshTokenExpiresAt: ComputedRef<Date | null>
    rawRefreshTokenExpiresAt: CookieRef<string | null>
    isTokenValid: ComputedRef<boolean>
    isRefreshTokenValid: ComputedRef<boolean>
}

export const makeCommonAuthState = <TSessionData>() => {
    const data = useState<TSessionData | undefined | null>('auth:data', () => undefined);

    const hasInitialSession = computed(() => !!data.value);

    // If session exists, initialize as already synced
    const lastRefreshedAt = useState<SessionLastRefreshedAt>('auth:lastRefreshedAt', () => {
        if (hasInitialSession.value) {
            return new Date();
        }

        return undefined;
    });

    // If session exists, initialize as not loading
    const loading = useState<boolean>('auth:loading', () => false);
    const status = computed<SessionStatus>(() => {
        if (loading.value) {
            return 'loading';
        } else if (data.value) {
            return 'authenticated';
        } else {
            return 'unauthenticated';
        }
    });

    return {
        data,
        loading,
        lastRefreshedAt,
        status,
    };
};

export const useAuthState = (): UseAuthStateReturn => {
    const config = useRuntimeConfig().public.auth as ModuleOptions;
    const commonAuthState = makeCommonAuthState<SessionData>();

    const _rawTokenCookie = useCookie<string | null>(
        config.token.cookieName,
        {
            default: () => null,
            maxAge: config.token.maxAgeInSeconds,
            sameSite: config.token.sameSiteAttribute,
        }
    );

    const rawToken = useState('auth:raw-token', () => _rawTokenCookie.value);
    watch(rawToken, () => { _rawTokenCookie.value = rawToken.value; });

    const token = computed(() => {
        if (rawToken.value === null) {
            return null;
        }

        return config.token.type.length > 0 ? `${config.token.type} ${rawToken.value}` : rawToken.value;
    });

    const _rawTokenExpiresAtCookie = useCookie<string | null>(
        `${config.token.cookieName}:expiresAt`,
        {
            default: () => null,
            maxAge: config.token.maxAgeInSeconds,
            sameSite: config.token.sameSiteAttribute,
        }
    );

    const rawTokenExpiresAt = useState('auth:raw-token-expiry', () => _rawTokenExpiresAtCookie.value);
    watch(rawTokenExpiresAt, () => { _rawTokenExpiresAtCookie.value = rawTokenExpiresAt.value; });

    const tokenExpiresAt = computed<Date>(() => {
        if (rawTokenExpiresAt.value === null) {
            return new Date();
        }

        let expiry = rawTokenExpiresAt.value;
        if (!expiry.endsWith('Z')) {
            expiry = `${expiry}Z`;
        }

        return new Date(expiry);
    });

    const _raw2FaCookie = useCookie<boolean | null>(
        `${config.token.cookieName}:2fa`,
        {
            default: () => null,
            maxAge: config.token.maxAgeInSeconds,
            sameSite: config.token.sameSiteAttribute,
        }
    );

    const rawIs2FaAuthenticated = useState('auth:is-2fa-authenticated', () => _raw2FaCookie.value);
    watch(rawIs2FaAuthenticated, () => { _raw2FaCookie.value = rawIs2FaAuthenticated.value; });

    const is2FaAuthenticated = computed(() => {
        return rawIs2FaAuthenticated.value;
    });

    const _rawRefreshTokenCookie = useCookie<string | null>(
        config.refreshToken.cookieName,
        {
            default: () => null,
            maxAge: config.refreshToken.maxAgeInSeconds,
            sameSite: config.refreshToken.sameSiteAttribute,
        }
    );

    const rawRefreshToken = useState('auth:raw-refresh-token', () => _rawRefreshTokenCookie.value);
    watch(rawRefreshToken, () => { _rawRefreshTokenCookie.value = rawRefreshToken.value; });

    const refreshToken = computed(() => {
        return rawRefreshToken.value;
    });

    const _rawRefreshTokenExpiresAtCookie = useCookie<string | null>(
        `${config.refreshToken.cookieName}:expiresAt`,
        {
            default: () => null,
            maxAge: config.refreshToken.maxAgeInSeconds,
            sameSite: config.refreshToken.sameSiteAttribute,
        }
    );

    const rawRefreshTokenExpiresAt = useState('auth:raw-refresh-token-expiry', () => _rawRefreshTokenExpiresAtCookie.value);
    watch(rawRefreshTokenExpiresAt, () => { _rawRefreshTokenExpiresAtCookie.value = rawRefreshTokenExpiresAt.value; });

    const refreshTokenExpiresAt = computed<Date>(() => {
        if (rawRefreshTokenExpiresAt.value === null) {
            return new Date();
        }

        return new Date(rawRefreshTokenExpiresAt.value);
    });

    const isTokenValid = computed(() => {
        if (token.value === null && tokenExpiresAt.value === null) {
            return false;
        }

        let expiry = tokenExpiresAt.value;

        // Set a buffer of 1 minute to ensure the refresh token is not expired
        expiry = new Date(expiry.getTime() - (60 * 1000));

        return expiry > new Date();
    });

    const isRefreshTokenValid = computed(() => {
        if (refreshToken.value === null) {
            console.log('Refresh token is null');
            return false;
        }

        if (refreshTokenExpiresAt.value === null) {
            console.log('Refresh token expiry is null');
            return false;
        }

        let expiry = refreshTokenExpiresAt.value;

        // Set a buffer of 1 minute to ensure the refresh token is not expired
        expiry = new Date(expiry.getTime() - (60 * 1000));

        const isValid = expiry > new Date();

        if (!isValid) {
            console.log('Refresh token is expired');
        }

        return isValid;
    });

    return {
        ...commonAuthState,
        token,
        rawToken,
        is2FaAuthenticated,
        rawIs2FaAuthenticated,
        rawTokenExpiresAt,
        tokenExpiresAt,
        rawRefreshTokenExpiresAt,
        refreshTokenExpiresAt,
        isTokenValid,
        refreshToken,
        rawRefreshToken,
        isRefreshTokenValid,
    };
};
